import welcome from "./utils/welcome";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

import {
  PasswordlessContextProvider,
  Passwordless as PasswordlessComponent,
  Fido2Toast,
} from "./components/passwordless/react";
import { Flowbite, ThemeModeScript } from "flowbite-react";
import ConfigureAmplifyClientSide from "./components/Amplify/ConfigureAmplify";
import { customTheme } from "./theme";

// import { Amplify } from "aws-amplify";
// import outputs from "@/amplify_outputs.json";

import "./components/passwordless/passwordless.css";
import "@aws-amplify/ui-react/styles.css";
import "@cloudscape-design/global-styles";
import "./globals.css";

import { RouterProvider, createRouter } from "@tanstack/react-router";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { queryClient } from "./queries/client";

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Amplify.configure(outputs);
// const queryClient = new QueryClient();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const backgroundImages = [
  "/images/backgrounds/city-of-gold-coast-unsplash.jpg",
  "/images/backgrounds/marek-piwnicki-unsplash.jpg",
  "/images/backgrounds/natalia-rudomin-unsplash.jpg",
  "/images/backgrounds/marek-piwnicki-2-unsplash.jpg",
  "/images/backgrounds/marek-piwnicki-3-unsplash.jpg",
];

root.render(
  <>
    <StrictMode>
      <ThemeModeScript />
      <RecoilRoot>
        <HelmetProvider>
          <PasswordlessContextProvider enableLocalUserCache={true}>
            <PasswordlessComponent
              brand={{
                // Get a random image from backgroundImages
                backgroundImageUrl:
                  backgroundImages[
                    Math.floor(Math.random() * backgroundImages.length)
                  ],
                // customerName: "Haus.",
                customerLogoUrl:
                  "/images/scrthq/SCRTHQ-LOGOMARK-CIRCLE-COLOR.png",
              }}
            >
              <Flowbite theme={{ theme: customTheme }}>
                <ConfigureAmplifyClientSide />
                <PersistQueryClientProvider
                  client={queryClient}
                  persistOptions={{ persister }}
                >
                  <RouterProvider router={router} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </PersistQueryClientProvider>
              </Flowbite>
            </PasswordlessComponent>
            <Fido2Toast />
          </PasswordlessContextProvider>
        </HelmetProvider>
      </RecoilRoot>
    </StrictMode>
  </>,
);

// welcome message for users in the console
welcome();

// ts(1208)
export {};
