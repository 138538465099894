/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $condition: ModelBillConditionInput
    $input: CreateBillInput!
  ) {
    createBill(condition: $condition, input: $input) {
      amount
      createdAt
      dueDateOfMonth
      frequency
      id
      link
      logoUrl
      method
      name
      nextDueDate
      notes
      owner
      paid
      payments {
        nextToken
        __typename
      }
      scheduled
      source
      status
      updatedAt
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $condition: ModelPaymentConditionInput
    $input: CreatePaymentInput!
  ) {
    createPayment(condition: $condition, input: $input) {
      amount
      createdAt
      date
      forBill {
        amount
        createdAt
        dueDateOfMonth
        frequency
        id
        link
        logoUrl
        method
        name
        nextDueDate
        notes
        owner
        paid
        scheduled
        source
        status
        updatedAt
        __typename
      }
      forBillId
      forBillName
      id
      method
      notes
      source
      updatedAt
      __typename
    }
  }
`;
export const createPoint = /* GraphQL */ `
  mutation CreatePoint(
    $condition: ModelPointConditionInput
    $input: CreatePointInput!
  ) {
    createPoint(condition: $condition, input: $input) {
      awardedTo
      category
      createdAt
      description
      id
      picture
      updatedAt
      value
      __typename
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill(
    $condition: ModelBillConditionInput
    $input: DeleteBillInput!
  ) {
    deleteBill(condition: $condition, input: $input) {
      amount
      createdAt
      dueDateOfMonth
      frequency
      id
      link
      logoUrl
      method
      name
      nextDueDate
      notes
      owner
      paid
      payments {
        nextToken
        __typename
      }
      scheduled
      source
      status
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $condition: ModelPaymentConditionInput
    $input: DeletePaymentInput!
  ) {
    deletePayment(condition: $condition, input: $input) {
      amount
      createdAt
      date
      forBill {
        amount
        createdAt
        dueDateOfMonth
        frequency
        id
        link
        logoUrl
        method
        name
        nextDueDate
        notes
        owner
        paid
        scheduled
        source
        status
        updatedAt
        __typename
      }
      forBillId
      forBillName
      id
      method
      notes
      source
      updatedAt
      __typename
    }
  }
`;
export const deletePoint = /* GraphQL */ `
  mutation DeletePoint(
    $condition: ModelPointConditionInput
    $input: DeletePointInput!
  ) {
    deletePoint(condition: $condition, input: $input) {
      awardedTo
      category
      createdAt
      description
      id
      picture
      updatedAt
      value
      __typename
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill(
    $condition: ModelBillConditionInput
    $input: UpdateBillInput!
  ) {
    updateBill(condition: $condition, input: $input) {
      amount
      createdAt
      dueDateOfMonth
      frequency
      id
      link
      logoUrl
      method
      name
      nextDueDate
      notes
      owner
      paid
      payments {
        nextToken
        __typename
      }
      scheduled
      source
      status
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $condition: ModelPaymentConditionInput
    $input: UpdatePaymentInput!
  ) {
    updatePayment(condition: $condition, input: $input) {
      amount
      createdAt
      date
      forBill {
        amount
        createdAt
        dueDateOfMonth
        frequency
        id
        link
        logoUrl
        method
        name
        nextDueDate
        notes
        owner
        paid
        scheduled
        source
        status
        updatedAt
        __typename
      }
      forBillId
      forBillName
      id
      method
      notes
      source
      updatedAt
      __typename
    }
  }
`;
export const updatePoint = /* GraphQL */ `
  mutation UpdatePoint(
    $condition: ModelPointConditionInput
    $input: UpdatePointInput!
  ) {
    updatePoint(condition: $condition, input: $input) {
      awardedTo
      category
      createdAt
      description
      id
      picture
      updatedAt
      value
      __typename
    }
  }
`;
