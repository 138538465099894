import { Amplify } from "aws-amplify";
import outputs from "../../../amplify_outputs.json";
import { Passwordless } from "../passwordless";

Amplify.configure(outputs);

Amplify.configure({
  ...Amplify.getConfig(),
  Predictions: outputs.custom.Predictions,
});

Passwordless.configure({
  cognitoIdpEndpoint: outputs.custom.VITE_COGNITO_IDP_ENDPOINT,
  userPoolId: outputs.auth.user_pool_id,
  clientId: outputs.auth.user_pool_client_id,
  fido2: {
    baseUrl: outputs.custom.VITE_FIDO2_BASE_URL,
    authenticatorSelection: {
      userVerification: "preferred",
    },
  },
  debug: console.debug,
});

export default function ConfigureAmplifyClientSide() {
  return null;
}
