import { CustomContentProps, SnackbarProvider } from "notistack";

import { notifications } from "../../config";

import Notifier from "./Notifier";
import { Ref, forwardRef } from "react";

// here how you can define your own notification component

const CustomNotification = forwardRef(function CustomNotification(
  { message }: CustomContentProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <>
      <div ref={ref}>{message}</div>
    </>
  );
});

function Notifications() {
  return (
    <SnackbarProvider
      maxSnack={notifications.maxSnack}
      Components={{
        customNotification: CustomNotification,
      }}
    >
      <Notifier />
    </SnackbarProvider>
  );
}

export default Notifications;
