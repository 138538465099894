"use client";

import { Modal, Spinner } from "flowbite-react";
import { HiX } from "react-icons/hi";
import PointCreateForm from "../ui-components/PointCreateForm";
import { useState } from "react";

export function AddPointModal({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Modal
        onClose={() => setShowModal(false)}
        popup
        show={showModal}
        size="xl"
      >
        <Modal.Body className="relative rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-5">
          <div className="mb-2 flex items-center justify-between pb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Add point
            </h3>
            <button
              onClick={() => setShowModal(false)}
              className="absolute right-5 top-4 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <HiX className="size-5" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <PointCreateForm
            overrides={{
              createdAt: {
                display: "none",
              },
              // awardedTo: {
              //   className: "w-1/2",
              // },
              category: {
                required: false,
                // className: "w-1/2",
              },
              value: {
                display: "none",
                defaultValue: 1,
                placeholder: "1",
                // className: "w-1/2",
              },
              updatedAt: {
                display: "none",
              },
              redeemed: {
                display: "none",
              },
              picture: {
                display: "none",
                hidden: true,
              },
              ClearButton: {
                display: "none",
                children: "Clear form",
                className: "!w-60 !h-8",
              },
              SubmitButton: {
                disabled: isLoading,
                // className: "mt-2",
                children: isLoading ? (
                  <>
                    <Spinner />
                    <span className="ml-2">Adding...</span>
                  </>
                ) : (
                  "Add Point"
                ),
              },
            }}
            onSubmit={(fields) => {
              fields.category = fields.category || "New food";
              fields.value = fields.value || 1;
              fields.createdAt = new Date().toISOString();
              fields.updatedAt = new Date().toISOString();
              setIsLoading(true);
              console.log("Adding Point with values: ", fields);
              return fields;
            }}
            onSuccess={(fields) => {
              console.log("Point added: ", fields);
              setIsLoading(false);
              setShowModal(false);
            }}
            onError={(fields, errorMessage: string) => {
              console.error("Error adding Point: ", errorMessage);
              console.log("Fields: ", fields);
              setShowModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
