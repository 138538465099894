import { createRootRoute, Outlet } from "@tanstack/react-router";
import React from "react";

import { Fragment } from "react";

// import { usePasswordless } from "../components/passwordless/react/hooks";
import { twMerge } from "tailwind-merge";

import { Helmet } from "react-helmet-async";

import Header from "../sections/Header";
import Notifications from "../sections/Notifications";
import { SnackbarProvider } from "notistack";
import MobileBottomNav from "../components/DaHaus/MobileBottomNav";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

export const Route = createRootRoute({
  component: () => (
    <Fragment>
      {/*
        Helmet allows us to set baseline metadata. If Helmet is used on child components,
        it will merge the metadata with the parent component with the child component's
        metadata taking precedence.
      */}
      <Helmet>
        <title>Da Haus</title>
        <meta name="description" content="Da Haus" />

        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      <div className={twMerge("bg-gray-50 dark:bg-gray-800")}>
        <Notifications />
        <SnackbarProvider />
        <div className="mx-auto flex h-screen flex-col items-center px-0 dark:text-white">
          <Header />
          <div className="flex items-start">
            <div
              id="main-content"
              className={twMerge(
                "size-full h-[calc(100vh-8rem)] overflow-y-auto",
              )}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <MobileBottomNav />
      <TanStackRouterDevtools />
    </Fragment>
  ),
});
