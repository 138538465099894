import {
  // Avatar,
  // Button,
  // Button,
  DarkThemeToggle,
  Dropdown,
  Navbar,
  theme,
  Tooltip,
  // theme,
} from "flowbite-react";
import { twMerge } from "tailwind-merge";
import { usePasswordless } from "../../components/passwordless/react";
import { Link } from "@tanstack/react-router";
import { HiOutlineUserCircle } from "react-icons/hi";

export default function Header() {
  const { tokensParsed, signOut } = usePasswordless();
  return (
    <header className="w-screen rounded-lg bg-gray-50 px-2 py-4 dark:bg-gray-800 sm:px-12">
      <Navbar className="rounded-lg bg-gray-50 shadow-md dark:bg-gray-700">
        <Tooltip content="Toggle dark mode">
          <DarkThemeToggle />
        </Tooltip>
        <div>
          <Link to="/" className="header-link px-2 sm:px-4 lg:px-16">
            Home
          </Link>
          <Link to="/bills" className="header-link px-2 sm:px-4 lg:px-16">
            Bills
          </Link>
          <Link to="/points" className="header-link px-2 sm:px-4 lg:px-16">
            Points
          </Link>
        </div>
        <Dropdown
          arrowIcon={true}
          inline
          label={
            <HiOutlineUserCircle
              size={22}
              className="text-gray-500 dark:text-gray-400"
            />
          }
          theme={{
            floating: {
              base: twMerge(theme.dropdown.floating.base, "rounded-lg"),
            },
          }}
        >
          <Dropdown.Header>
            <strong className="block text-sm">
              Hi, {tokensParsed?.idToken?.name}!
            </strong>
            <span className="block truncate text-sm font-medium text-gray-500 dark:text-gray-400">
              {tokensParsed?.idToken?.email}
            </span>
          </Dropdown.Header>
          {/* <Link to="/">
            <Dropdown.Item className="text-gray-500 dark:text-gray-400">
              Home
            </Dropdown.Item>
          </Link>
          <Link to="/bills">
            <Dropdown.Item className="text-gray-500 dark:text-gray-400">
              Bills
            </Dropdown.Item>
          </Link>
          <Dropdown.Divider /> */}
          <Dropdown.Item
            className="text-gray-500 dark:text-gray-400"
            onClick={signOut}
          >
            Sign out
          </Dropdown.Item>
        </Dropdown>
      </Navbar>
    </header>
  );
}
